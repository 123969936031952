const isClient = typeof window !== 'undefined';

const userAgent = {
  isMobile: {
    touchPoint: () => isClient && window.navigator.maxTouchPoints > 1,
    Android: () => isClient && window.navigator.userAgent.match(/Android/i),
    IOS: () => isClient && window.navigator.userAgent.match(/iPhone|iPad|iPod|ios/i),
    Assemblr: () => isClient && window.navigator.userAgent.toLowerCase().includes('assemblr'),
    any: () => isClient
    && (userAgent.isMobile.Android()
    || userAgent.isMobile.IOS()
     || userAgent.isMobile.Assemblr()),
    anyWithTouchPoint: () => isClient && (userAgent.isMobile.Android() || userAgent.isMobile.IOS() || userAgent.isMobile.Assemblr() || (userAgent.isMobile.touchPoint() && window.navigator.userAgent.toLowerCase().includes('safari'))),
  },
};

export default userAgent;
