import axios from 'axios';
import { getAuth, getIdToken } from 'firebase/auth';
import { jwtDecode } from 'jwt-decode';
import baseURL from '../Config/Api';
import fetchAdapter from './fetchAdapter';

const apiService = axios.create({
  baseURL,
  adapter: fetchAdapter,
});

export const reqInterceptor = async (config) => {
  const auth = getAuth();
  const currentUser = new Promise((resolve) => {
    auth.onAuthStateChanged((user) => {
      resolve(user);
    });
  });

  let token = localStorage.getItem('_token');
  if (token) {
    config.headers.Authorization = token;
  }

  if (currentUser) {
    try {
      token = await getIdToken(currentUser);
      if (token) {
        localStorage.setItem('_token', token);
        config.headers.Authorization = token;
      }
    } catch (e) {
      console.log(e);
    }
  }

  try {
    if (config.url.includes(':uid') && (currentUser?.uid || token)) {
      config.url = config.url.replace(':uid', currentUser?.uid || jwtDecode(token).user_id);
    }
  } catch (e) {
    console.log(e);
  }

  return config;
};

apiService.interceptors.request.use(reqInterceptor);

apiService.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error),
);

export default apiService;
